import React, { useState } from "react";
import { BookOutline, SearchOutline, RocketOutline } from "react-ionicons";

const services = [
  {
    id: 1,
    price: "$29",
    pricefigure: "per essay",
    title: "Essay Bank",
    desc: "While your profile is one-of-a-kind, past successful MBA applicants share similarities with your background. Let their success stories inspire your own narrative! Navigate through our extensive essay library to find the perfect match for your background. Explore our Essay Bank, where individual gems await at just $29 each.Why settle for generic when you can resonate with someone who's walked a similar path? Your quest for an outstanding essay begins here, where resonance meets excellence! ",
  },
  {
    id: 2,
    price: "$99",
    pricefigure: "per revision",
    title: "Essay Revision",
    desc: "Struggling to turn your MBA essay from 'meh' to 'marvelous'? For just $49, our essay revision service is your secret weapon! I magine a dream team of former MBA whizzes, armed with red pens and killer instincts , ready to transform your essay from drab to fab. We dont just pinpoint typos; we tell you how to add the zing, zap, and zoom your essay needs to stand out. Think of us as your personal trainers for your words – except we promise no heavy lifting.Say goodbye to sleepless nights and hello to a masterpiece. Lets give the admissions committee something to talk about!",
  },
  {
    id: 3,
    price: "$99",
    pricefigure: "(or success fee only)",
    title: "Mock Interviews",
    desc: "Gear up for your dream school interview like a pro! Dive into our world of affordable mock interviews ($99 each) or grab our fantastic risk-free deal ($999 for 3 mock interviews that not only sharpen your skills but also come with a guarantee. That's right, if your dream school doesn't extend that coveted offer, we'll gladly refund the investment. It's a win-win, or as we like to call it, a win-try-and-get-your-money-back)! Meet our interview experts – real MVPs who aced their own interviews and are here to pass on the secrets. Don't leave your dreams to chance, practice with those who've been there, done that, and are ready to help you nail it too. Let's turn those interview nerves into interview cheers!",
  },
  {
    id: 4,
    price: "$49",
    pricefigure: "per letter",
    title: "Recommendation Letters",
    desc: "Feel like your recommenders need a nudge in the right direction? Why not inspire them with real samples that scored big? Snag our recommendation letter samples for just $49 each! Some savvy applicants crafted these gems themselves, or maybe their mentors spilled the secret sauce. Either way, it's top-notch inspiration for your recommenders to shine!",
  },
  {
    id: 5,
    price: "$299",
    pricefigure: "(or success fee only)",
    title: "Holistic Review",
    desc: "Ready to give your application the VIP treatment? Dive into our Holistic Review service! For $299 (or $999 but you only pay if you get an offer from your dream school), our experts will scrutinize every nook and cranny of your application, ensuring it's a standout in the admissions lineup. We're not fortune tellers, but we know what catches eyes and leaves a lasting impression. Let's turn your application into a masterpiece – a symphony of experiences, achievements, and personality. Because it's not just about applying; it's about making a statement. Don't leave it to chance; let's make your entire application unforgettable!",
  },
];

function MobileCarousel() {
  const [mostPopular, setMostPopular] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : services.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < services.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="flex items-center justify-center">
      <button onClick={handlePrev} className="p-3 mr-1 bg-gray-200 rounded-full">
        &#10094;
      </button>
      <div className="flex space-x-4">
        {services.slice(currentIndex, currentIndex + 1).map((service) => (
          <div
            key={service.id}
            className={`max-w-[70vw] min-h-[75vh] max-h-[80vh] p-10 border-2 rounded-xl flex flex-col border-dashed border-gray-400 bg-gray-100 overflow-y-scroll ${
              service.id === 1
                ? " bg-gradient-to-tr from-blue-500 via-indigo-500 to-indigo-600 text-black"
                : "bg-white text-black"
            }`}
          >
            {service.id === 1 && (
              <div className="relative w-full text-right text-sm -mt-7 mb-2 text-gray-200">
                <span className="bg-green-500 bg-opacity-95 w-fit py-1 px-3 -mr-8 rounded-md font-semibold">Most Popular</span>
              </div>
            )}
            <div className="flex flex-row">
              <div className={`font-bold text-2xl text-transparent bg-clip-text ${ service.id === 1 ? "bg-gradient-to-tr from-[#ff930f] via-[#fffb91] to-[#ffd95b]" : " bg-gradient-to-tr from-blue-500 via-indigo-500 to-indigo-600 "}`}>{service.title}</div>
            </div>
            <div
              className={`flex justify-center p-8 mt-5 rounded-3xl border-[1px] ${
                service.id === 1
                  ? " bg-gradient-to-r from-indigo-400 to-indigo-600 border-none"
                  : "bg-gray-100 border-gray-200"
              }`}
            >
              <span
                className={`font-bold text-4xl ${
                  service.id === 1 ? "text-gray-200" : " text-black"
                }`}
              >
                {service.price}
              </span>
              <span className={` ml-1 ${
                service.id === 1
                  ? "text-gray-200"
                  : "text-black"
              }`}>{service.pricefigure}</span>
            </div>
            <br></br>
            {service.id === 1 && (
              <div className="relative w-full text-left mt-2 text-white">
                <SearchOutline color="white" className="float-left mr-4"></SearchOutline>
                <span className="mb-4">While your profile is one-of-a-kind, <b>past successful MBA applicants share similarities with you.</b></span><br /><br></br>
                <BookOutline color="white" className="float-left mr-4"></BookOutline>
                <span className="mb-4">Navigate through our <b>extensive essay library</b> to find the <b>perfect match for your background.</b></span><br /><br></br>
                <RocketOutline color="white" className="float-left mr-4"></RocketOutline>
                <span>Let their success stories <b>inspire your own narrative</b> for just $29 per gem!</span>
              </div>
            )}

            {service.id === 2 && (
              <div className="relative w-full text-left mt-2 text-black">
                <span className="mb-4">Upgrade your MBA essay from <b>'meh' to 'outstanding' for just $99!</b></span><br /><br></br>
                <span className="mb-4">Picture a squad of <b>former MBA geniuses armed with red pens</b>, ready to jazz up your essay.</span><br /><br></br>
                <span><b>We don't just fix typos;</b> we add the zing, zap, and zoom to make your essay pop!</span><br></br><br></br>
              </div>
            )}

            {service.id === 3 && (
              <div className="relative w-full text-left mt-2 text-black">
                <span className="mb-4">Gear up for your dream school interview like a boss! Get into the groove with our affordable <b>mock interviews at just $99 each.</b></span><br /><br></br>
                <span className="mb-4">Or snag our unbeatable deal: <b>$999 for 3 mock interviews with a guarantee!</b> Yep, if your dream school doesn't bite, we'll refund you faster than you can say "acceptance letter".</span><br /><br></br>
              </div>
            )}

            {service.id === 4 && (
              <div className="relative w-full text-left mt-2 text-black">
                <span className="mb-4">Want to give your recommenders a <b>gentle push in the right direction?</b> Why not dazzle them with real samples that hit the bullseye?</span><br /><br></br>
                <span className="mb-4">Grab our <b>recommendation letter samples for just $49 each!</b> Crafted by the finest minds in the game, these gems are sure to spark inspiration.</span><br /><br></br>
              </div>
            )}

            {service.id === 5 && (
              <div className="relative w-full text-left mt-2 text-black">
                <span className="mb-4">Ready to <b>give your application the VIP treatment?</b> Dive into our Holistic Review service!</span><br /><br></br>
                <span className="mb-4">For <b>$299 (or $999, but you only pay if you get that golden ticket from your dream school)</b>, our experts will <b>dissect every detail of your application</b>, ensuring it shines brighter than a disco ball at a party.</span><br /><br></br>
              </div>
            )}
          </div>
        ))}
      </div>
      <button onClick={handleNext} className="p-3 ml-1 bg-gray-200 rounded-full">
        &#10095;
      </button>
    </div>
  );

}

export default MobileCarousel;
