import React, { useEffect, useRef } from "react";

const PopupWithArrow = ({ message, onClose }) => {
  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      ref={popupRef}
      className="absolute left-1/2 transform -translate-x-1/2 mt-16 text-xs w-[20vw] p-4 bg-white border rounded shadow-lg max-sm:w-[80vw]"
    >
      <span className="">
        <button onClick={onClose} className="text-black float-right -mr-2 -mt-3">
          x
        </button>
      </span>
    <br></br>
      <div className="relative -mt-3">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default PopupWithArrow;
