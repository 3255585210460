import React, { useState } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className=" text-white absolute right-2">
      <div className="w-fit px-4">
        <div className="flex justify-between h-16">
          {/* Hamburger button */}
          <div className="flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-white mt-2"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {/* Hamburger Icon */}
              {isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Menu */}
      <div className={`${isOpen ? 'opacity-100 absolute right-5 transition-opacity transition-3' : ' opacity-0 right-5 absolute transition-opacity transition-3'} sm:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <a href="/essayBank" className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 hover:bg-gray-700">
            Essay Bank
          </a>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSflsVj36xRcAfx_ex6hFL5YcXmhW0tzZ_KTSG2yHtLGK-0ocw/viewform?usp=sf_link" className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 hover:bg-gray-700">
            MBA Grad
          </a>
          {/* <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 hover:bg-gray-700">
            Services
          </a>
          <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 hover:bg-gray-700">
            Contact
          </a> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;