import React, { useState } from "react";
import { Element, scroller } from "react-scroll";
import { LogoNodejs, ChevronDownOutline } from "react-ionicons";
import { useParallax } from "react-scroll-parallax";



const FullLine = ({ title, subtitle, imagem }) => {
  const [clickEffect, setClickEffect] = useState(false);
  const [rectEffect, setRectEffect] = useState(false);
  const [lockEffect, setLockEffect] = useState(false);
  const [clickEffect2, setClickEffect2] = useState(false);
  const [clickEffect3, setClickEffect3] = useState(false);

  
  

  const options = {
    // Your options here, for example:
    duration: 500,
    smooth: true,
  };

  const handleClick = () => {
    setClickEffect(true);
    setLockEffect(true);
    setTimeout(() => {
      setRectEffect(true);
      //setClickEffect(false);
    }, 200);

    setTimeout(() => {
      scroller.scrollTo("about", {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 175, // Scrolls to element + 50 pixels down the page
        // ... other options
        ignoreCancelEvents: true,
      });
    }, 400);
  };

  const handleClick2 = () => {
    setClickEffect2(true);

    setTimeout(() => {
      scroller.scrollTo("matrix", {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 440, // Scrolls to element + 440 pixels down the page
        // ... other options
        ignoreCancelEvents: true,
      });
    }, 100);
  }

  const handleClick3 = () => {
    setClickEffect3(true);

    setTimeout(() => {
      scroller.scrollTo("services", {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 30, // Scrolls to element + 440 pixels down the page
        // ... other options
        ignoreCancelEvents: true,
      });
    }, 100);
  }

  const parallax = useParallax({
    onProgressChange: (progress) => {
      if (parallax.ref.current) {
        // set progress to CSS variable
        parallax.ref.current.style.setProperty(
          "--progress",
          progress.toString
        );
      }
    },
  });


  return (
    <div className="absolute w-screen flex justify-center items-end h-full max-md:hidden">
      <button onClick={handleClick}>
        <div className="relative">
          <div
            className={
              clickEffect === true
                ? "relative mb-[35vh] z-10 w-36 h-36 bg-[#5987FF] focus:shadow-none pointer-events-none rounded-full shadow-sm transition duration-300 max-sm:hidden"
                : "relative mb-[35vh] z-10 w-36 h-36 bg-[#5987FF] hover:bg-[#476CCC] rounded-full shadow-xl transition duration-300 max-sm:hidden"
            }
          >
            <img
              className="w-32 h-32 object-contain pl-[15px] rounded-sm pointer-events-none"
              src="lock.png"
            />
          </div>
        </div>
      </button>
      <div className="absolute left-[50% + 1px] top-[61.8vh]">
        <div className="">
          <div className="relative z-10 w-1 h-32 transition-colors duration-300 bg-white" />
          <div
            ref={parallax.ref}
            className={`absolute z-10 w-1 h-[0] bg-[#5987FF] top-[0vh]`}
            style= {{minHeight: 'calc(1vh * var(--progress))'}}
          />
          
          <div
            className={`absolute z-10 w-1 h-[108vh] bg-[#5987FF] top-[20vh] transition-height duration-[0s]`}
          />
        </div>
        <div className="absolute w-4 h-4 bg-black z-10 top-[85vh] left-[50%] -ml-[8px] rounded-full shadow-md"></div>
      </div>
      <div className=" cursor-pointer z-30 absolute top-[190vh] left-1/2 -ml-[3vh] h-fit w-fit text-white">
        <ChevronDownOutline
          name="chevron"
          height="6vh"
          width="6vh"          
          onClick={handleClick2}
          />
      </div>

      <div className="absolute left-[50% + 1px] top-[196vh]">
        <div className="">
          <div className="" />
          <div
            className={`relative z-0 w-1 h-[93vh] bg-gray-200 top-[0vh] transition-height duration-[0s] `}
          />
          
        </div>
      </div>


      <div className=" cursor-pointer z-30 absolute top-[290vh] left-1/2 -ml-[3vh] h-fit w-fit text-white">
        <ChevronDownOutline
          name="chevron"
          height="6vh"
          width="6vh"          
          onClick={handleClick3}
        />
      </div>
    </div>
  );
};

export default FullLine;
