import React, { useState } from "react";

import BlogPost from "./components/BlogPost";
import data from "./components/blogStarterData";

import CheckoutForm from "./checkoutForm.js";

import SmallFooter from "./components/Compound/Footer/SmallFooter";
import Header from "./components/Compound/Header/Header";
import Filter from "./components/Filter";

const Blog = () => {
  const [selectedType, setSelectedType] = useState("All");

  const filteredPosts =
    selectedType === "All"
      ? data
      : data.filter((post) => post.type === selectedType);

  const blogTypes = [...new Set(data.map((post) => post.type))];
  return (
    <div className="bg-white scroll">
      <Header />
      <div className="mt-24 bg-white">
        <div className="flex justify-center mb-2 pt-2 py-4">
          <Filter
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            types={blogTypes}
          />
        </div>
        <div className="bg-gray-200 h-1"></div>
        <div className="bg-white flex flex-grow-0 flex-shrink-0 items-center h-auto mt-6 mb-24">
          <div className="container mx-auto p-4 w-7/12">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
              {filteredPosts.map((post) => (
                <BlogPost
                  sect={post.sect}
                  link={post.link}
                  key={post.id}
                  title={post.title}
                  description={post.description}
                  imageUrl={post.imageUrl}
                />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 h-1"></div>
      <div className="fixed bottom-0 inset-x-0 max-sm:invisible"><SmallFooter></SmallFooter></div>
    </div>
  );
};

export default Blog;
