import React, { useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
} from "firebase/firestore/lite";
import { Brush, LockClosedOutline } from "react-ionicons";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./checkoutForm.js";

import Footer from "./components/Compound/Footer/Footer";
import Header from "./components/Compound/Header/Header";

import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
} from "@mui/material";


const MyForm = () => {
  const { handleSubmit, control } = useForm();

  const handleClick = (data) => {
    // Forward data to the email address or perform any necessary action
  };
  const stripePromise = loadStripe(
    "pk_live_51P0D0200VzqWk9s7dsHnt701lslmgX6LutJDM1eYSD6DdQgqST2ovfBcRlFTvmdPkHZYl2vNbbP0FPfp7qDVR0dc00ipgN74Ky"
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [rowCount, setRowCount] = useState(1); // Initial number of rows
  const [colCount, setColCount] = useState(5); // Initial number of columns

  const [moda4sOpen, setIsOpen] = React.useState(false);

  const options = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const onSubmit = (data) => {
    console.log(data); // You can handle form submission here
  };

  const [formData, setFormData] = useState({
    targetSchool: queryParams.get("targetSchool"),
    preMbaIndustry: queryParams.get("preMbaIndustry"),
    Nationality: queryParams.get("Nationality"),
    education: queryParams.get("education"),
    PMG: queryParams.get("PMG"),
    diversity: queryParams.get("diversity"),
  });

  const newLocal = (
    <div id="app-root" className="bg-white h-[100vh] overflow-y-hidden mt-20">
      <Header />
      <div className="w-screen text-center pt-5 max-sm:px-10">
        <span className="text-center text-xl font-bold"> 
          Essays Located!<br></br>
        </span>
        <span className=" italic text-md font-light">
          {formData.targetSchool} {formData.preMbaIndustry}{" "}
          {formData.Nationality} {formData.education} {formData.PMG}{" "}
          {formData.diversity}
          <br></br>
        </span>
        <span className="text-center">
          Please enter information below, and the essay will be specifically
          picked for your needs and emailed in 1-2 days.
        </span>
      </div>

      <div className=" flex flex-wrap justify-center items-center w-full p-10">
        <div
          onSubmit={handleSubmit(onSubmit)}
          className="bg-gray-100 p-12 w-[85vw] max-w-lg mx-auto md:mt-8"
        >
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm targetSchool={formData.targetSchool} preMbaIndustry={formData.preMbaIndustry} Nationality={formData.Nationality} education={formData.education} PMG={formData.PMG} diversity={formData.diversity}/>
          </Elements>
        </div>
      </div>
    </div>
  );
  return newLocal;
};

export default MyForm;
