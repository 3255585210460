import React, { useState, useEffect, useMemo } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { Brush, LockClosedOutline } from "react-ionicons";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import SmallFooter from "./components/Compound/Footer/SmallFooter";
import Header from "./components/Compound/Header/Header";
import { InformationCircleOutline } from "react-ionicons";
import PopupWithArrow from "./PopupWithArrow";
import Footer from "./components/Compound/Footer/Footer";
import { Oval } from "react-loader-spinner";
import countryList from 'react-select-country-list'

import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  CircularProgress,
} from "@mui/material";

const TIME = 750


const MyForm = () => {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();
  const { show, setShow } = useState(true);
  const options = useMemo(() => countryList().getData(), [])
  const [countryValue, setCountryValue] = useState('');

  const [activePopupId, setActivePopupId] = useState(null);
  const [popupMessage, setPopupMessage] = useState("");

  const handleIconClick = (id, message) => {
    if (activePopupId === id) {
      setActivePopupId(null);
      setPopupMessage("");
    } else {
      setActivePopupId(id);
      setPopupMessage(message);
    }
  };

  const handleClosePopup = () => {
    setActivePopupId(null);
    setPopupMessage("");
  };

  const changeHandler = value => {
    setCountryValue(value)
  }


  const handleClick = (e) => {
    if (
      formData.targetSchool == "" &&
      formData.preMbaIndustry == "" &&
      formData.Nationality == "" &&
      formData.education == "" &&
      formData.PMG == "" &&
      formData.diversity == ""
    ) {
      alert(
        "Please select one criteria so we can find the best essay for you!"
      );
      return;
    }
    e.preventDefault();
    const queryParams = new URLSearchParams(formData).toString();
    navigate(`/checkout?${queryParams}`);
  };

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    targetSchool: "",
    preMbaIndustry: "",
    Nationality: "",
    education: "",
    PMG: "",
    diversity: "",
  });

  const [rowCount, setRowCount] = useState(1); // Initial number of rows
  const [colCount, setColCount] = useState(4); // Initial number of columns

  const newLocal = (
    <div className="bg-black">
      <Header />
      <div
        id="app-root"
        className="bg-blue-100 overflow-x-hidden overflow-y-clip mt-12 flex flex-col"
      >
        <div className="mt-[5vh]  ">
          <h1 className="text-center font-bold text-2xl pt-2 pb-3 max-sm:px-2">
            Search our Essay Database
          </h1>
          <p className="text-center text-md max-sm:px-2">
            Use the filters below to sift through our Essay Bank for essays that
            <span> </span>
            <span className="underline decoration-yellow-200 decoration-2">
              resonate with your background
            </span>
            . <br></br> Remember,{" "}
            <span className="underline decoration-green-300 decoration-2">
              filters are optional
            </span>{" "}
            — choose the ones that are important to you.
          </p>

          <div className="flex justify-center mt-10">
            <div className="bg-white flex flex-wrap lg:justify-center items-center rounded-lg drop-shadow-md w-fit p-10 max-sm:items-start max-sm:p-3 z-10">
              <form onSubmit={handleClick} className="w-[85vw]">
                <FormControl
                  className="mr-2 mb-2 p-2 md:w-[15%] max-sm:w-full"
                  sx={{ mr: 1 }}
                >
                  <InputLabel id="targetSchoolLabel">
                    Target School
                    {/* <SchoolOutline className="float-right max-sm:invisible ml-10"></SchoolOutline> */}
                  </InputLabel>

                  <Controller
                    name="targetSchool"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Select
                          className="mr-2 w-full max-sm:w-5/6 mb-2 rounded-sm"
                          labelId="targetSchoolLabel"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            field.onBlur(); // Remove focus from the select dropdown to hide selected value
                            const { name, value } = e.target;
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: value,
                            }));
                            setLoading(true); // Set loading to true when dropdown value changes
                            // Simulate loading delay (you can replace this with actual loading logic)
                            setTimeout(() => {
                              setLoading(false); // Set loading back to false after some time (simulating data loading)
                            }, TIME);
                          }}
                        >
                          <MenuItem value="HBS">Harvard</MenuItem>
                          <MenuItem value="GSB">Stanford</MenuItem>
                          <MenuItem value="Columbia">Columbia</MenuItem>
                          <MenuItem value="Wharton">Wharton</MenuItem>
                          <MenuItem value="MIT">MIT</MenuItem>
                          <MenuItem value="INSEAD">INSEAD</MenuItem>
                        </Select>
                        <span className="absolute left-[11vw] cursor-pointer">
                          <InformationCircleOutline
                            width="4"
                            className="float-right"
                            onClick={() =>
                              handleIconClick(
                                1,
                                "If you're eyeing more than one dream school, no problem! Just select them one at a time, and keep in mind that essays are purchased separately."
                              )
                            }
                          />
                        </span>
                      </>
                    )}
                  />
                  {activePopupId === 1 && (
                    <PopupWithArrow
                      message={popupMessage}
                      onClose={handleClosePopup}
                    />
                  )}
                </FormControl>
                <FormControl
                  className="mr-2 mb-2 p-2 md:w-[15%] max-sm:w-full"
                  sx={{ mr: 1 }}
                >
                  <InputLabel id="preMbaIndustryLabel">
                    Pre-MBA Industry
                  </InputLabel>
                  <Controller
                    name="preMbaIndustry"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Select
                          labelId="preMbaIndustryLabel"
                          {...field}
                          className="mr-2 w-full max-sm:w-5/6 mb-2 rounded-sm"
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            field.onBlur(); // Remove focus from the select dropdown to hide selected value
                            const { name, value } = e.target;
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: value,
                            }));
                            setLoading(true); // Set loading to true when dropdown value changes
                            // Simulate loading delay (you can replace this with actual loading logic)
                            setTimeout(() => {
                              setLoading(false); // Set loading back to false after some time (simulating data loading)
                            }, TIME);
                          }}
                        >
                          <MenuItem value="Consulting">
                            Consulting (MBB)
                          </MenuItem>
                          <MenuItem value="non-MBB">
                            Consulting (non-MBB)
                          </MenuItem>
                          <MenuItem value="Consumer">
                            CPG / Retail / E-commerce
                          </MenuItem>
                          <MenuItem value="Entrepreneurship">
                            Entrepreneurship
                          </MenuItem>
                          <MenuItem value="Biotech">
                            Healthcare / Biotech
                          </MenuItem>
                          <MenuItem value="Impact">Impact investing</MenuItem>
                          <MenuItem value="Industry">
                            Corporate Strategy
                          </MenuItem>
                          <MenuItem value="Investment">
                            Investment Banking
                          </MenuItem>
                          <MenuItem value="Military">Military</MenuItem>
                          <MenuItem value="Non-Profit">
                            Non-Profit / Government / Education
                          </MenuItem>
                          <MenuItem value="Private Equity">
                            Private Equity
                          </MenuItem>
                          <MenuItem value="Technology">Technology</MenuItem>
                          <MenuItem value="Venture Capital">
                            Venture Capital
                          </MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        <span className="absolute left-[11vw] cursor-pointer">
                          <InformationCircleOutline
                            width="5"
                            className="float-right"
                            onClick={() =>
                              handleIconClick(
                                2,
                                "Where did you work before (or for an extended period) business school?"
                              )
                            }
                          />
                        </span>
                      </>
                    )}
                  />
                  {activePopupId === 2 && (
                    <PopupWithArrow
                      message={popupMessage}
                      onClose={handleClosePopup}
                    />
                  )}
                </FormControl>
                <FormControl
                  className="mr-2 mb-2 p-2 rounded-lg md:w-[15%] max-sm:w-full"
                  sx={{ mr: 1 }}
                >
                  <InputLabel id="nationality">
                    Nationality/Regional Background
                  </InputLabel>
                  <Controller
                    name="Nationality"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Select
                          labelId="nationality"
                          {...field}
                          className="mr-2 w-full max-sm:w-5/6 mb-2  rounded-sm"
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            field.onBlur(); // Remove focus from the select dropdown to hide selected value
                            const { name, value } = e.target;
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: value,
                            }));
                            setLoading(true); // Set loading to true when dropdown value changes
                            // Simulate loading delay (you can replace this with actual loading logic)
                            setTimeout(() => {
                              setLoading(false); // Set loading back to false after some time (simulating data loading)
                            }, TIME);
                          }}
                        >
                          <MenuItem value="USA">United States</MenuItem>
                          <MenuItem value="Asia">Asia</MenuItem>
                          <MenuItem value="Europe">Europe</MenuItem>
                          <MenuItem value="Mexico, Central, and South america">
                            Mexico, Central & South America
                          </MenuItem>
                          <MenuItem value="Canda">Canada</MenuItem>
                          <MenuItem value="Maine">Middle East</MenuItem>
                          <MenuItem value="Africa">Africa</MenuItem>
                          <MenuItem value="Oceania">Oceania</MenuItem>
                        </Select>
                        <span className="absolute left-[11vw] cursor-pointer">
                          <InformationCircleOutline
                            width="5"
                            className="float-right"
                            onClick={() =>
                              handleIconClick(
                                3,
                                "Let us know where you spent the majority of your work time. If your upbringing holds more significance in shaping who you are today, share where you grew up."
                              )
                            }
                          />
                        </span>
                      </>
                    )}
                  />
                  {activePopupId === 3 && (
                    <PopupWithArrow
                      message={popupMessage}
                      onClose={handleClosePopup}
                    />
                  )}
                </FormControl>
                <FormControl
                  className="mr-2 mb-2 p-2 rounded-lg md:w-[15%] max-sm:w-full"
                  sx={{ mr: 1 }}
                >
                  <InputLabel id="education">Education</InputLabel>
                  <Controller
                    name="education"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Select
                          labelId="education"
                          {...field}
                          className="mr-2 w-full max-sm:w-5/6 mb-2  rounded-sm"
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            field.onBlur(); // Remove focus from the select dropdown to hide selected value
                            const { name, value } = e.target;
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: value,
                            }));
                            setLoading(true); // Set loading to true when dropdown value changes
                            // Simulate loading delay (you can replace this with actual loading logic)
                            setTimeout(() => {
                              setLoading(false); // Set loading back to false after some time (simulating data loading)
                            }, TIME);
                          }}
                        >
                          <MenuItem value="Economics">Economics</MenuItem>
                          <MenuItem value="Engineering">Engineering</MenuItem>
                          <MenuItem value="Business/Commerce">
                            Business/Commerce
                          </MenuItem>
                          <MenuItem value="Arts/Humanities">
                            Arts/Humanities
                          </MenuItem>
                          <MenuItem value="Social Science">
                            Social Science
                          </MenuItem>
                          <MenuItem value="Math/Physical Sciences">
                            Math/Physical Sciences
                          </MenuItem>
                          <MenuItem value="Math/Physical Sciences">
                            Other
                          </MenuItem>
                        </Select>
                        <span className="absolute left-[11vw] cursor-pointer">
                          <InformationCircleOutline
                            width="5"
                            className="float-right"
                            onClick={() =>
                              handleIconClick(
                                4,
                                "What was your primary field of study in undergrad?"
                              )
                            }
                          />
                        </span>
                      </>
                    )}
                  />
                  {activePopupId === 4 && (
                    <PopupWithArrow
                      message={popupMessage}
                      onClose={handleClosePopup}
                    />
                  )}
                </FormControl>
                <FormControl
                  className="mr-2 mb-2 p-2 rounded-lg md:w-[15%] max-sm:w-full"
                  sx={{ mr: 1 }}
                >
                  <InputLabel id="PMG">Post-MBA Goals</InputLabel>
                  <Controller
                    name="PMG"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Select
                          labelId="PMG"
                          {...field}
                          className="mr-2 w-full max-sm:w-5/6 mb-2 rounded-sm "
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            field.onBlur(); // Remove focus from the select dropdown to hide selected value
                            const { name, value } = e.target;
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: value,
                            }));
                            setLoading(true); // Set loading to true when dropdown value changes
                            // Simulate loading delay (you can replace this with actual loading logic)
                            setTimeout(() => {
                              setLoading(false); // Set loading back to false after some time (simulating data loading)
                            }, TIME);
                          }}
                        >
                          <MenuItem value="Consulting">Consulting</MenuItem>
                          <MenuItem value="Entrepreneurship">
                            Entrepreneurship
                          </MenuItem>
                          <MenuItem value="Impact">Impact investing</MenuItem>
                          <MenuItem value="Industry">Industry</MenuItem>
                          <MenuItem value="Investment">
                            Investment Banking
                          </MenuItem>
                          <MenuItem value="Non-Profit">
                            Non-Profit / Government / Education
                          </MenuItem>
                          <MenuItem value="Private Equity">
                            Private Equity
                          </MenuItem>
                          <MenuItem value="Venture Capital">
                            Venture Capital
                          </MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        <span className="absolute left-[11vw] cursor-pointer">
                          <InformationCircleOutline
                            width="5"
                            className="float-right"
                            onClick={() =>
                              handleIconClick(
                                5,
                                "[Highly optional] What is your target industry post-MBA? If it's not relevant for your application, please select N/A."
                              )
                            }
                          />
                        </span>
                      </>
                    )}
                  />
                  {activePopupId === 5 && (
                    <PopupWithArrow
                      message={popupMessage}
                      onClose={handleClosePopup}
                    />
                  )}
                </FormControl>
                <FormControl
                  className="mr-2 mb-2 p-2 rounded-lg md:w-[15%] max-sm:w-full"
                  sx={{ mr: 1 }}
                >
                  <InputLabel id="diversity">Diversity</InputLabel>
                  <Controller
                    name="diversity"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Select
                          labelId="diversity"
                          {...field}
                          className="mr-2 w-full max-sm:w-5/6 mb-2  rounded-sm "
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            field.onBlur(); // Remove focus from the select dropdown to hide selected value
                            const { name, value } = e.target;
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: value,
                            }));
                            setLoading(true); // Set loading to true when dropdown value changes
                            // Simulate loading delay (you can replace this with actual loading logic)
                            setTimeout(() => {
                              setLoading(false); // Set loading back to false after some time (simulating data loading)
                            }, TIME);
                          }}
                        >
                          <MenuItem value="fg" className="w-full">
                            First Generation
                          </MenuItem>
                          <MenuItem value="LGBTQ+">LGBTQ+</MenuItem>
                          <MenuItem value="Asian American">
                            Asian American
                          </MenuItem>
                          <MenuItem value="Black or African American">
                            Black or African American
                          </MenuItem>
                          <MenuItem value="Hispanic or Latino">
                            Hispanic or Latino
                          </MenuItem>
                          <MenuItem value="Multi-Race">Multi-Race</MenuItem>
                          <MenuItem value="Disability">Disability</MenuItem>
                        </Select>
                        <span className="absolute left-[11vw] cursor-pointer">
                          <InformationCircleOutline
                            width="5"
                            className="float-right"
                            onClick={() =>
                              handleIconClick(
                                6,
                                "[Highly optional] Which diversity criterion do you feel most connected to, if any?"
                              )
                            }
                          />
                        </span>
                      </>
                    )}
                  />
                  {activePopupId === 6 && (
                    <PopupWithArrow
                      message={popupMessage}
                      onClose={handleClosePopup}
                    />
                  )}
                </FormControl>
                <div
                  className="w-full flex flex-col justify-center items-center"
                  sx={{ mr: 1 }}
                >
                  <button
                    className="mr-5 my-2 text-md px-5 py-1 rounded-full text-white bg-[#5987FF]"
                    type="submit"
                  >
                    Buy Essays
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="bg-white rounded-2xl -mt-10 flex justify-center max-sm:invisible max-sm:absolute max-sm:max-h-0 pb-[7vh]">
            {loading && (
              <div className="absolute z-30 mr-4">
                <Oval
                  visible={true}
                  color="black"
                  secondaryColor="#5987FF"
                  strokeWidth="3"
                />
              </div>
            )}

            <div
              className="mx-24 pt-20"
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(${colCount}, 1fr)`,
                gap: "1px",
              }}
            >
              {[...Array(rowCount)].map((_, rowIndex) =>
                [...Array(colCount)].map((_, colIndex) => (
                  <img
                    key={`${rowIndex}-${colIndex}`}
                    src="example_file.png"
                    alt="Image"
                    style={{ width: "7vw", height: "auto" }}
                    className="drop-shadow-sm border-2 border-gray-100 mx-12 rounded-sm max-sm:absolute max-sm:invisible"
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0  bottom-0 max-sm:invisible">
        <SmallFooter />
      </div>
    </div>
  );
  return newLocal;
};

export default MyForm;
