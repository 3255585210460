import React, { useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
} from "firebase/firestore/lite";
import { ArrowBack } from "react-ionicons";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { BrowserRouter as Router, Routes, Route, useParams, Link } from 'react-router-dom';
import posts from './components/blogData';


import CheckoutForm from "./checkoutForm.js";

import SmallFooter from "./components/Compound/Footer/SmallFooter";
import Header from "./components/Compound/Header/Header";

const Interview = () => {

  const { postId } = useParams();
  const post = posts[postId];

  return (
    <div>
      <Header></Header>
      
      {post ? (
        <>
          <div className="mt-24">
          <Link to={`/blog`}><div className="ml-40 flex flex-row"><ArrowBack height='1rem'></ArrowBack><p4 className="text-sm -mt-0.5 ml-0.5">Back</p4></div></Link>
            <h2 className="text-2xl pb-3 text-center">Genius Profile</h2>
            <h2 className="text-4xl font-semibold text-center mt-4">🌎 {post.hometown}</h2>
            <p className="text-center">💼 Previous Occuption: {post.prevOcc}</p>
            <p className="text-center">🎓 MBA Program: {post.mbaProg}</p>
            <p className="text-center">➡️ Current Role: {post.currRole}</p>
            <div className="mx-40 mt-10 text-lg">
              <p className="font-bold mb-5 mt-10">1️⃣ {post.questOne}</p><p className="italic ml-6">{post.ansOne}</p>
              <p className="font-bold mb-5 mt-10">2️⃣ {post.questTwo}</p><p className="italic ml-6">{post.ansTwo}</p>
              <p className="font-bold mb-5 mt-10">3️⃣ {post.questThr}</p><p className="italic ml-6">{post.ansThr}</p>
              <p className="font-bold mb-5 mt-10">4️⃣ {post.questFour}</p><p className="italic ml-6">{post.ansFour}</p>
              <p className="mt-7"><span>🫵 MBA Essay Genius takeaway:</span> {post.endAdvice}</p>
              <div className="bg-black h-[.1rem] mt-7 mb-7"></div>
              <div className="mb-20">
                <div className="mb-7">📌 This interview was part of our “Meet the Geniuses” series, where we interview the brains behind the best collection of MBA essays (yep, that’s us – 500+ stellar essays from top business schools and counting 🤓). We’ve asked four questions and received brutally honest, no-BS answers from people who did exactly what you’re aiming to do (getting into your dream business school, not scoring dates, you rascal!). Check out all the interviews <a className="text-blue-500 underline hover:text-blue-300" href='/blog'>here</a> 👀</div>
                <div>🔥 Ready to get inspired? Buy all the vetted essays you need, whenever you want, right <a className="text-blue-500 underline hover:text-blue-300" href='/essayBank'>here</a> 📲</div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h2>Post not found</h2>
      )}
    </div>
  );
};

export default Interview;
