import React, { useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
} from "firebase/firestore/lite";
import { Brush, LockClosedOutline } from "react-ionicons";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./checkoutForm.js";

import Footer from "./components/Compound/Footer/Footer";
import Header from "./components/Compound/Header/Header";

const Fail = () => {
  return (
    <div className="bg-black flex flex-col">
      <Header />
      <div className="bg-white min-h-[70vh] flex flex-col w-screen">
        <span className="text-4xl text-center justify-center mt-40 font-bold ">Error 500</span><br></br>
        <span className="text-center text-md">Please email us to receive your essays.</span>
        <span className="text-center text-md">support@mbaessaygenius.com</span>
      </div>
      <div className="absolute left-0 right-0  bottom-0 max-sm:invisible">
          <Footer />
        </div>
    </div>
  );
};

export default Fail;
