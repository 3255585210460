import ServiceCarousel from './Carousel'
import MobileCarousel from './MobileCarousel'
import { Element } from 'react-scroll'

const ServicesSection = () => {
  return (
    <section
      className="flex flex-col w-full justify-center items-center mx-auto px-6 py-12 my-8 "
      id="services"
    >
      <Element name="services"></Element>
      <div className="w-4/5">
        <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>
        <div className="max-sm:invisible max-sm:absolute">
          <ServiceCarousel></ServiceCarousel>
        </div>
        <div className="md:invisible md:absolute">
          <MobileCarousel></MobileCarousel>
        </div>
        
      </div>
    </section>
  );
};

export default ServicesSection;
