import React, { useState } from "react";
import { Element } from 'react-scroll';

const HelpedSection = () => {
    return (
        <div className="flex flex-col justify-center items-center max-sm:px-3 max-sm:h-fit">
            <Element name="about"></Element>
            <div/>
            <div className="mt-6 -mb-3 bg-white z-20 p-3">
                <h1 className="text-3xl text-center font-bold">See exactly how top MBA applicants get offers from the best business schools in the world</h1>
            </div>
            <div className="flex justify-center items-center p-4 w-4/5 h-auto bg-white z-20">
                <img src="e1.png" alt="Image 5" style={{ width: '15%', height: '40%' }} className="mr-7 max-sm:invisible max-sm:absolute" />
                <img src="Harward-logo.png" alt="Image 1" style={{ width: '15%', height: '25%' }} className="mr-7 max-sm:invisible max-sm:absolute" />
                <img src="b.png" alt="Image 2" style={{ width: '15%', height: '60%' }} className="mr-7 max-sm:invisible max-sm:absolute" />
                <img src="c1.png" alt="Image 3" style={{ width: '10%', height: '20%' }} className="mr-7 max-sm:invisible max-sm:absolute" />
                <img src="d.png" alt="Image 4" style={{ width: '15%', height: '60%' }} className="max-sm:invisible max-sm:absolute" />
            </div>
            <div className="md:invisible md:absolute flex justify-center items-center -mt-4 z-20">
                <img src="Harward-logo.png" alt="Image 1" style={{ width: '23%' }} className="mr-7 mb-1" />
                <img src="b.png" alt="Image 2" style={{ width: '25%' }} className="mr-5" />
                <img src="e1.png" alt="Image 4" style={{ width: '25%'}} className="" />
            </div>
        </div>
    );
};


export default HelpedSection;