import { Element } from 'react-scroll';

function Matrix() {
    return (
        <div className='flex justify-center bg-[#5987FF] pb-12 max-sm:hidden'>
        <div className="flex flex-col justify-center ">
            <p1 className="font-bold text-white z-10 text-3xl pt-4 mt-8 mb-4 select-auto text-center">What separates us from our competitors?</p1>
        <div className="flex justify-center items-center mt-2 bg-white max-sm:invisible max-sm:absolute pointer-events-none select-none w-[45vw] shadow-lg rounded-3xl">
            <Element name="Services"></Element>
            <div className=" w-screen flex flex-wrap justify-center">
                <img src="venn.png" className='pointer-events-none max-w-[45vw] z-10'></img>
            </div>
            
        </div>
        </div>
        </div>
    );
}

export default Matrix;