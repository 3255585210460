// src/components/BlogPost.js
import React from 'react';

const BlogPost = ({ sect, title, description, imageUrl, link }) => {
  return (
    <div className="bg-white border rounded-lg overflow-y-auto overflow-x-clip aspect-auto max-w-[20rem] min-w-[15rem] ">
      <a href={`/blog/${sect}/${link}`} className="block h-full">
        <img src={imageUrl} alt={title} className="w-full h-2/3 object-cover shadow-md hover:shadow-sm" />
        <div className="p-4 h-1/3 flex flex-col justify-between group">
          <h3 className="font-semibold text-base group-hover:text-gray-700">{description}..</h3>
          <h3 className="group-hover:text-blue-300 text-blue-500 text-left text-sm">Read more</h3>
        </div>
        
      </a>
    </div>
  );
};

export default BlogPost;
