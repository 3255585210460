// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Homepage";
import MyForm from "./Form";
import MbaGraduates from "./MbaGraduates";
import { ParallaxProvider } from "react-scroll-parallax";
import Modal from 'react-modal';
import Checkout from "./checkout"
import Success from "./success"
import Fail from "./fail"
import Blog from "./Blog"
import Interview from "./Interview"
import BlogPost from "./ClassicBlog"
import PageNotFound from "./404Page";


const App = () => {
  return (
    <Router>
      <ParallaxProvider>
        <Routes>
     
          <Route path="/" element={<HomePage />} />

          <Route path="/essayBank" element={<MyForm />} />
          {/* <Route path="/mbaGraduates" element={<MbaGraduates />} /> */}
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Fail />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/interview/:postId" element={<Interview />} />
          <Route path="/blog/post/:postId" element={<BlogPost />} />
          <Route
                    path="*"
                    element={<PageNotFound />}
                />
        </Routes>
      </ParallaxProvider>
    </Router>
  );
};

export default App;
