// src/data/blogPosts.js
const posts = {

    postone: { id: 1, hometown: 'New York City, NY', prevOcc: 'Marketing Manager at a tech startup', mbaProg: 'Wharton School of the University of Pennsylvania', currRole: 'Product Manager at a Fortune 500 Tech Company', 
    questOne: 'What was the biggest surprise for you during your MBA application process?', ansOne: 'Oh, where to start? Let’s just say the application process is a lot like dating in New York City: expensive, exhausting, and full of unsolicited advice. The biggest surprise was definitely the sheer volume of introspection required. You think you know yourself until you’re asked to describe your ‘leadership style’ for the tenth time. By the end, I felt like I needed a mini-MBA just to understand my own life.', 
    questTwo: 'How did your background in marketing help you during your MBA studies?', ansTwo: 'Marketing is basically the art of selling people stuff they don’t need with money they don’t have. My background in marketing was like a cheat code for MBA classes. Understanding consumer behavior gave me a leg up in strategy classes, and I could whip out a SWOT analysis in my sleep. Plus, being a marketing manager in a startup teaches you to think on your feet and deal with chaos, which, fun fact, is also what an MBA program feels like sometimes.',
    questThr: 'What was your favorite part of the MBA program at Wharton?', ansThr: 'Hands down, the people. You’re surrounded by brilliant, ambitious, and slightly insane individuals who all think they’re the next Elon Musk. The networking opportunities are priceless – I’m talking about rubbing elbows with future CEOs and entrepreneurs. Plus, Wharton’s alumni network is like having a VIP pass to the business world’s most exclusive club. I’ve never eaten so much free sushi in my life.',
    questFour: 'Any advice for prospective MBA applicants who might be reading this?', ansFour: 'Yes, don’t panic! Easier said than done, I know. Start early, be authentic, and for the love of all that is holy, proofread your essays. Think of your application as a story – your story. Admissions committees can smell BS from a mile away, so keep it real. And remember, the MBA journey is a marathon, not a sprint. Pace yourself, and don’t forget to enjoy the ride – after all, sleep is overrated, right?',
    endAdvice: 'This interviewee’s journey from the bustling streets of NYC to the hallowed halls of Wharton is a testament to the power of perseverance, a solid marketing strategy, and a healthy sense of humor. If you’re feeling daunted by the MBA application process, take a leaf out of her book: stay authentic, embrace the chaos, and always be ready to pivot. And who knows? Maybe one day, you’ll be the one giving advice and reminiscing about your own MBA adventure.' 
  },
  posttwo: { id: 2, hometown: 'Chicago, IL', prevOcc: 'Associate at a bulge-bracket investment bank', mbaProg: 'Harvard Business School', currRole: 'Venture Capitalist at a leading VC firm', 
  questOne: 'What motivated you to pursue an MBA at Harvard Business School?', ansOne: 'Honestly, it was a mix of ambition and curiosity. Working as an investment banker was great, but I felt like a cog in a very large machine. I wanted to understand the broader picture – how businesses are built, scaled, and led. Plus, the HBS brand is like the business world’s golden ticket. Who wouldn’t want that?', 
  questTwo: 'How did your finance background influence your MBA journey?', ansTwo: 'Finance is all about the numbers, and that foundation was incredibly helpful. But the MBA opened my eyes to the people side of business – leadership, negotiation, and teamwork. My quantitative skills gave me an edge in finance and accounting classes, but I had to work hard on the softer skills, like public speaking and networking. It was a well-rounded experience.',
  questThr: 'What was the most challenging part of your MBA program?', ansThr: 'The case method was both a blessing and a curse. It’s fantastic for learning real-world applications, but it’s also relentless. You’re constantly on the spot, and there’s no hiding in the back row. Balancing the intense workload with networking and extracurriculars was like trying to juggle flaming swords while riding a unicycle.',
  questFour: 'Any memorable moments or experiences from your time at HBS?', ansFour: 'Absolutely. The FIELD Global Immersion project was a standout. I got to travel to South Korea and work with a local startup. It was eye-opening to see business challenges in a completely different context and incredibly rewarding to help develop solutions that had a real impact. Plus, getting to explore Seoul wasn’t too shabby either.',
  endAdvice: 'This finance whiz found his golden ticket not just through the HBS brand, but by embracing the softer skills that make business tick. His story teaches us that while quantitative prowess can get you in the door, it\'s the ability to lead, negotiate, and collaborate that truly sets you apart. So, prospective MBAs, don\'t just crunch numbers; work on becoming a well-rounded business leader who can juggle flaming swords and ride unicycles with grace.' 
    // Add more posts here
  }, postthr: { id: 3, hometown: 'San Francisco, CA', prevOcc: 'Software Engineer at a mid-sized tech company', mbaProg: 'Stanford Graduate School of Business', currRole: 'Entrepreneur and Founder of a tech startup', 
  questOne: 'Why did you decide to switch from software engineering to pursuing an MBA?', ansOne: 'As a software engineer, I was always intrigued by the bigger picture – the business strategy, the product decisions, and how everything fits together. I wanted to transition from just building products to also shaping the vision and direction of those products. An MBA seemed like the perfect way to make that leap.', 
  questTwo: 'How did your technical background benefit you in business school?', ansTwo: 'It was a game-changer. Understanding the technical side gave me a unique perspective in classes like operations and product management. I could bridge the gap between business and technology, which is a highly valued skill in today’s tech-driven world. Plus, it gave me a lot of credibility among my peers, especially in group projects.',
  questThr: 'What was the most rewarding aspect of your time at Stanford GSB?', ansThr: 'The entrepreneurial ecosystem was incredible. Being in Silicon Valley, you’re at the heart of innovation. The resources, the mentorship, and the community support for startups are unparalleled. Launching my own startup while still in school was challenging, but the experience was invaluable. And having classmates who are equally passionate about changing the world was deeply inspiring.',
  questFour: 'What’s your advice for those considering an MBA?', ansFour: 'Go for it, but be clear about your goals. An MBA is a significant investment, so know what you want to get out of it. Leverage the network and take advantage of every opportunity. And don’t be afraid to step out of your comfort zone – that’s where the real growth happens. Lastly, enjoy the ride and make lifelong friends along the way.',
  endAdvice: 'This techie-turned-entrepreneur shows that understanding the nuts and bolts of technology is a superpower in the business world. The key takeaway? Your technical background is gold in an MBA setting, especially when you’re in the heart of Silicon Valley. We all now that GSB loves software engineers with entrepreneurial aspirations – show them that’s you!' 
    // Add more posts here
  }, postfour: { id: 4, hometown: 'Mumbai, India', prevOcc: 'MBB Consultant', mbaProg: 'INSEAD', currRole: 'Strategy Director at a global corporation', 
  questOne: 'What drew you to the MBA program at INSEAD?', ansOne: 'INSEAD’s global focus was a huge draw for me. I wanted to expand my horizons beyond the consulting world and immerse myself in a truly international environment. The chance to study in both France and Singapore was particularly appealing. Plus, the one-year program was a big plus – I wanted to get back into the workforce quickly.', 
  questTwo: 'How did your consulting experience shape your MBA experience?', ansTwo: 'Consulting teaches you to think strategically and work under pressure, which came in handy during the MBA. I was used to fast-paced environments and complex problem-solving, so I could hit the ground running. However, I had to unlearn some habits, like always having the “right” answer. INSEAD encouraged more open-ended, creative thinking.',
  questThr: 'What was the highlight of your INSEAD experience?', ansThr: 'The diversity of the cohort was amazing. I learned as much from my classmates as I did from my professors. Working on group projects with people from all over the world gave me insights into different cultures and business practices. The study trips and treks were also highlights – nothing beats learning about business in the context of a new country.',
  questFour: 'What’s your advice for future MBA applicants?', ansFour: 'Embrace the diversity and be open to new experiences. INSEAD is about broadening your perspective, so dive in with an open mind. Build strong relationships with your peers – they will be your global network. And don’t forget to have fun. It’s a challenging year, but also one of the most rewarding and enjoyable experiences you’ll ever have.',
  endAdvice: 'This ex-consultant\'s journey highlights the magic of diversity and international exposure. INSEAD’s global focus and one-year program provided the perfect platform to unlearn rigid consulting habits and embrace creative thinking. Future applicants, take note: immerse yourself in diverse experiences, build a global network, and remember that sometimes the best application answers aren\'t the "right" ones but the ones that come from open-ended, creative collaboration.' 
    // Add more posts here
  }, postfive: { id: 5, hometown: 'Toronto, Canada', prevOcc: 'Nonprofit Manager', mbaProg: 'London Business School', currRole: 'Social Impact Consultant', 
  questOne: 'What inspired you to pursue an MBA after working in the nonprofit sector?', ansOne: 'I wanted to bridge the gap between the nonprofit world and the business world. Nonprofits can learn a lot from the efficiency and strategic planning of businesses. An MBA seemed like the perfect way to gain those skills and make a greater impact in my field. Plus, I was ready for a new challenge and some career growth.', 
  questTwo: 'How did your background in nonprofit management influence your MBA journey?', ansTwo: 'It gave me a unique perspective. While many of my classmates were focused on profit margins, I was thinking about social impact. My experience in managing volunteers and limited resources translated well into leadership and management courses. And I think my nonprofit background added a valuable dimension to class discussions about corporate responsibility and ethics.',
  questThr: 'Can you share a memorable experience from your MBA time?', ansThr: 'Living in London was absolutely amazing – it was my first time living outside of Canada and I got to discover an incredible city inside out while making friends from all over the world. I must confess that I’ll likely spend more money in weddings across all continents than in my MBA tuition, but that’s what life is about!',
  questFour: 'What’s your advice for those from a nonprofit background considering an MBA?', ansFour: 'Do it! The skills you gain will be invaluable, and you’ll bring a unique and needed perspective to the business world. Be clear about your goals and how an MBA fits into them. Take full advantage of the resources available to you, and don’t be afraid to step outside your comfort zone. And remember, the business world needs more people who think about social impact.',
  endAdvice: 'Bridging the nonprofit and business worlds, this social impact consultant demonstrates that an MBA can supercharge your ability to drive change. Her advice is gold: bring your unique perspective, focus on your goals, and don’t shy away from challenges. If you’re from the nonprofit sector, let your passion for social impact shine through in your application, and remember, the world needs more business leaders who care about making a difference.' 
    // Add more posts here
  }, postsix: { id: 6, hometown: 'Los Angeles, CA', prevOcc: 'Sales Director at a consumer goods company', mbaProg: 'Columbia Business School', currRole: 'Chief Operating Officer at a startup', 
  questOne: 'What motivated you to pursue an MBA at Columbia Business School?', ansOne: 'I was looking to transition from sales to a broader leadership role, and I knew I needed more than just my on-the-job experience to do that. Columbia’s strong focus on leadership and its prime location in New York City were big draws for me. Plus, I wanted to build a strong network in one of the world’s business capitals.', 
  questTwo: 'How did your sales background help you during your MBA?', ansTwo: 'Sales is all about understanding people and building relationships, which was a huge advantage in business school. Negotiation classes felt like home turf, and I had a leg up in any scenario involving customer insights or market strategy. But I had to work hard on the quantitative stuff – finance and accounting were definitely outside my comfort zone.',
  questThr: 'Any standout memories from your time at Columbia?', ansThr: 'The most memorable experience was definitely the startup lab. I teamed up with some classmates to launch a business, and we got to pitch our idea to actual investors. The pressure was intense, but it was an incredible learning experience. We didn’t get funded, but the feedback we received was worth its weight in gold.',
  questFour: 'What advice would you give to prospective MBA students?', ansFour: 'Network like it’s your job, because in many ways, it is. The connections you make during your MBA will be your professional support system. Also, don’t shy away from the hard stuff. Embrace the subjects that scare you – that’s where the most growth happens. And take advantage of every opportunity, whether it’s a guest lecture or a social event. You never know where it might lead.',
  endAdvice: 'This sales guru-turned-COO underscores the value of networking and embracing discomfort. His journey through Columbia’s MBA program highlights the importance of leveraging people skills while tackling the quantitative challenges head-on. Prospective MBAs should focus on building strong relationships, stepping out of their comfort zones, and making the most of every opportunity. After all, the connections you make could lead to your next big break.' 
    // Add more posts here
  }, postsev: { id: 7, hometown: 'São Paulo, Brazil', prevOcc: 'Entrepreneur in the hospitality industry', mbaProg: 'London Business School', currRole: 'Managing Director at a hospitality consultancy', 
  questOne: 'What prompted you to pursue an MBA at London Business School?', ansOne: 'I’d been running my own business for several years and felt I had reached a plateau. I needed fresh insights and skills to take my business to the next level. LBS’s strong focus on entrepreneurship and its diverse, international student body made it the perfect choice. Plus, I loved the idea of living in London – it’s a global business hub.', 
  questTwo: 'How did your entrepreneurial experience shape your MBA journey?', ansTwo: 'Running a business teaches you resilience and resourcefulness, which are crucial in an MBA program. My experience gave me a practical edge in discussions about business strategy and operations. However, I had to adjust to the academic environment and learn to back up my instincts with data and analysis. It was a valuable transition.',
  questThr: 'Any particularly memorable moments during your MBA?', ansThr: 'One memorable moment was participating in the London Start-up Weekend. It was an intense, 54-hour event where we developed and pitched a business idea. It was exhilarating and exhausting, but our team ended up winning second place. The feedback from the judges and the connections I made were invaluable.',
  questFour: 'What advice would you give to future MBA applicants?', ansFour: 'Know your why. Be clear about why you’re pursuing an MBA and what you hope to achieve. This clarity will guide you through the application process and help you make the most of your time in the program. Also, immerse yourself fully – take advantage of the diverse opportunities, and don’t be afraid to step out of your comfort zone. And remember, your classmates are one of your greatest resources. Learn from them and build lasting relationships.',
  endAdvice: 'An entrepreneur at heart, this hospitality pro used his MBA to break through a plateau and gain fresh insights. His advice is crystal clear: know your “why” and immerse yourself fully in the MBA experience. For aspiring MBAs, remember to leverage your entrepreneurial spirit, back up your instincts with solid data, and build lasting relationships. And don’t forget to savor every moment – the journey is as rewarding as the destination.' 
    // Add more posts here
  }, posteight: { id: 8, hometown: 'Bogota, Colombia', prevOcc: 'Investment Banker', mbaProg: 'Harvard Business School', currRole: 'Vice President at a Private Equity Fund', 
  questOne: 'What inspired you to pursue an MBA at Harvard Business School?', ansOne: 'Coming from the fast-paced world of investment banking in Colombia, I wanted to broaden my horizons and gain a deeper understanding of global markets. Harvard’s reputation, extensive network, and case method pedagogy were huge draws. Plus, let’s be real, the HBS brand on a resume opens a lot of doors.', 
  questTwo: 'How did your investment banking background help you during your MBA studies?', ansTwo: 'Investment banking is like boot camp for business. The long hours, high pressure, and intricate financial modeling gave me a solid foundation. During the MBA, this experience translated well into finance and strategy classes. However, HBS pushed me beyond the numbers to focus on leadership and the human side of business, which was both challenging and rewarding.',
  questThr: 'What was the most surprising aspect of your MBA experience at HBS?', ansThr: 'The biggest surprise was the diversity of thought. You’re not just learning from textbooks but from classmates with backgrounds in everything from tech startups to non-profits. The case method amplifies this diversity, turning every class into a lively debate. It’s humbling and eye-opening to see how different perspectives can approach the same problem.',
  questFour: 'What advice would you give to prospective MBA applicants?', ansFour: 'Be genuine in your applications. The admissions team at HBS can see through fluff. Know your story, your goals, and how the MBA fits into that narrative. Also, be ready to engage with a diverse set of ideas and people. The more open and adaptable you are, the more you’ll gain from the experience. And remember, while the academics are important, the relationships you build are priceless.',
  endAdvice: 'This interviewee’s journey from Latin America’s investment banking sector to Harvard’s prestigious halls is a masterclass in leveraging industry prestige to get into business school. His story highlights the importance of genuine self-reflection, with no fluff storylines. If you’re eyeing HBS, take his advice to heart: be real, be ready to think about your life and career experience from all angles, and go the extra mile when crafting your application.' 
    // Add more posts here
  }, postnine: { id: 9, hometown: 'Lagos, Nigeria', prevOcc: 'Operations Manager at an Oil & Gas Company', mbaProg: 'Harvard Business School', currRole: 'Chief Operating Officer at an Energy Startup', 
  questOne: 'What motivated you to pursue an MBA at Harvard Business School?', ansOne: 'Working in Nigeria’s oil and gas industry, I saw firsthand the complexities and opportunities in the energy sector. I wanted to gain strategic insights and leadership skills to drive innovation in this field. Harvard’s global perspective and strong emphasis on leadership development were key motivators for me.', 
  questTwo: 'How did your operations management background influence your MBA journey?', ansTwo: 'Operations management is about efficiency and problem-solving under constraints, which are crucial skills in any business environment. At HBS, I could apply these skills to case studies and real-world scenarios. The experience also taught me to think critically about resource management and sustainability, which are essential in today’s energy landscape.',
  questThr: 'What was the highlight of your MBA experience at HBS?', ansThr: 'e FIELD Global Immersion project stands out. I traveled to Asia to work with a renewable energy startup. It was an incredible opportunity to apply classroom learnings to a real-world challenge and see the impact of our work firsthand. Plus, the cultural exchange was enriching, broadening my global business perspective.',
  questFour: 'What advice would you give to future MBA applicants?', ansFour: 'Embrace your unique background and how it shapes your perspective. Business schools value diversity in experience and thought. Be clear about your goals and how the MBA will help you achieve them. Engage deeply with the program and your peers – the learning extends far beyond the classroom. And don’t forget to enjoy the journey, because it’s as much about personal growth as it is about professional advancement.',
  endAdvice: 'Our interviewee’s path from Lagos to Harvard underscores the importance of leveraging industry experience while seeking broader strategic and leadership skills. His story is a testament to the value of global exposure and hands-on learning. Aspiring MBA candidates should heed his advice: highlight your unique background, stay focused on your goals, and fully engage with every opportunity the program offers.' 
    // Add more posts here
  }, postten: { id: 10, hometown: 'Seoul, South Korea', prevOcc: 'MBB Consultant', mbaProg: 'Harvard Business School', currRole: 'Chief Strategy Officer at a Tech Company', 
  questOne: 'What prompted you to pursue an MBA at Harvard Business School?', ansOne: 'After several years in management consulting, I wanted to shift from advising companies to driving strategy within a company. Harvard’s strong emphasis on leadership and its unparalleled alumni network made it the ideal place to make that transition. Plus, the case method at HBS was a perfect fit for my learning style.', 
  questTwo: 'How did your consulting experience shape your MBA journey?', ansTwo: 'Consulting is about quickly understanding complex problems and devising actionable solutions, which is excellent preparation for an MBA. During my time at HBS, I was able to leverage my analytical skills and experience in diverse industries. However, the program also pushed me to develop my leadership abilities and soft skills, which are crucial for a strategic role.',
  questThr: 'What was the most rewarding part of your MBA experience?', ansThr: 'The friendships and networks I built. HBS brings together some of the brightest minds from around the world, and the camaraderie and mutual support are incredible. One standout experience was a group project where we developed a business plan for a tech startup. The collaboration and insights from my diverse colleagues were invaluable.',
  questFour: 'Any advice for those considering an MBA?', ansFour: 'First, be clear about why you want an MBA and what you hope to achieve. This clarity will guide your decisions and help you make the most of the program. Second, don’t be afraid to step out of your comfort zone – whether it’s taking on leadership roles, exploring new industries, or building new skills. Finally, build genuine relationships with your peers and professors. These connections are some of the most valuable takeaways from the MBA experience.',
  endAdvice: 'This interviewee’s journey from Seoul’s consulting sphere to a strategic leadership role via Harvard highlights the transformative power of an MBA. His story emphasizes the importance of leveraging past experiences while being open to new challenges and opportunities. Prospective applicants should focus on their unique strengths, seek out diverse experiences, and cultivate strong networks to maximize their MBA journey.' 
    // Add more posts here
  }
};
  export default posts;
  