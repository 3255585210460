import React, { useState } from "react";
import { Transition } from "@headlessui/react";

const HeroSection = ({ title, subtitle, image }) => {
  return (
    <div className="bg-[#5987FF] section-1 text-white text-center lg:text-left h-[70vh] max-sm:h-fit max-sm:px-3 px-24 pointer-events-none mt-12">
      <div className="container mx-auto w-7/8 flex flex-col lg:flex-row items-center .justify-items-end h-200 max-sm:mb-10">
        <div className="lg:max-w-full">
          <div className="relative">
            <div className="absolute bg-yellow-0 w-[25vw] -mt-1 h-1 -z-10 ml-[17.5vw] opacity-100"></div>
            <h1 className="text-3xl lg:text-5xl xl:text-6xl font-bold leading-none mt-12 w-auto">
              Unlock the secrets to a <br></br> 
                successful MBA application
            </h1>
          </div>
          <div className="relative">
            <div className="absolute bg-green-300 max-sm:w-[90vw] w-[20vw] -mt-6 h-2 -z-10 opacity-100"></div>
            <p className="text-xl lg:text-2xl mt-8 font-light">
              Dive into our database of 500+ essays from <span className="text-green-300 font-bold">rockstar</span> MBA applicants
              & join our <br></br>community of <span className="text-yellow-200 font-bold">top</span> business school MBAs
            </p>
          </div>
          <div className="mt-4 md:mt-12">
            <a href="/essayBank"><button className="bg-green-400 hover:bg-green-500 text-white font-bold py-3 px-6 rounded-lg pointer-events-auto">
              MBA Essay Bank
            </button>
            </a>
          </div>
          <div className="mt-3 mb-12 font-light">
            <span className="text-yellow-200 font-bold">Proven</span> Essays, <span className="text-green-300 font-bold">Proven</span> Success
          </div>
        </div>
        <div className="lg:mt-0 lg:w-1/4 float-right inline pl-48 max-sm:hidden">
          <img
            className="max-sm:h-36 max-sm:w-36 max-sm:left-1/4 max-sm:top-[55vh] h-52 w-52 absolute object-cover rotate-[25deg] left-[82vw] top-[13vh]"
            src="docs.png"
          />
          <img
            className="w-36 h-36 -rotate-[30deg] object-cover absolute left-[75vw] top-[37vh]"
            src="word.png"
          />
          <img
            className="h-14 w-14 rotate-[15deg] absolute object-cover left-[85vw] top-[55vh]"
            src="notion.svg"
          />
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
