// src/components/FilterNav.js
import React from 'react';

const FilterNav = ({ selectedType, setSelectedType, types }) => {
  return (
    <nav className="mb-4 text-center">
      <ul className="flex space-x-4">
        <li>
          <button
            onClick={() => setSelectedType('All')}
            className={`${
              selectedType === 'All' ? 'border-[#5683f6] rounded-full px-2 border-2 bg-blue-200' : 'text-black border-[#5683f6] bg-white border-2 rounded-full px-2'
            } hover:bg-blue-200`}
          >
            All
          </button>
        </li>
        {types.map((type) => (
          <li key={type}>
            <button
              onClick={() => setSelectedType(type)}
              className={`${
                selectedType === type ? 'border-[#5683f6] rounded-full px-2 border-2 bg-blue-200' : 'text-black border-[#5683f6] bg-white border-2 rounded-full px-2'
              } hover:bg-blue-200`}
            >
              {type}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FilterNav;
