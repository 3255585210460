// src/data/blogPosts.js
const blogPosts = [
    {
      id: 1,
      sect: "interview",
      title: "Wharton School of the University of Pennsylvania",
      description: "Now a Product Manager at a Fortune 500 Tech Company.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "postone",
      type: "meet the geniuses 👋"
    },
    {
      id: 2,
      sect: "interview",
      title: "Harvard Business School",
      description: "Now a Venture Capitalist at a leading VC firm.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "posttwo",
      type: "meet the geniuses 👋",
      type: "harvard 🔥"
    },
    {
      id: 3,
      sect: "interview",
      title: "Stanford Graduate School of Business",
      description: "Now an Entrepreneur and Founder of a tech startup.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "postthr",
      type: "meet the geniuses 👋",
      type: "stanford 🚀"
    },
    {
      id: 4,
      sect: "interview",
      title: "INSEAD",
      description: "Now a Strategy Director at a global corporation.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "postfour",
      type: "meet the geniuses 👋"
    },
    {
      id: 5,
      sect: "interview",
      title: "London Business School",
      description: "Now a Social Impact Consultant.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "postfive",
      type: "meet the geniuses 👋"
    },
    {
      id: 6,
      sect: "interview",
      title: "Columbia Business School",
      description: "Now a Chief Operating Officer at a startup.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "postsix",
      type: "meet the geniuses 👋"
    },
    {
      id: 7,
      sect: "interview",
      title: "London Business School",
      description: "Now a Managing Director at a hospitality consultancy.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "postsev",
      type: "meet the geniuses 👋"
    },
    {
      id: 8,
      sect: "interview",
      title: "Harvard Business School",
      description: "Now a Vice President at a Private Equity Fund.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "posteight",
      type: "meet the geniuses 👋",
      type: "harvard 🔥"
    },
    {
      id: 9,
      sect: "interview",
      title: "Harvard Business School",
      description: "Now a Chief Operating Officer at an Energy Startup.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "postnine",
      type: "meet the geniuses 👋",
      type: "harvard 🔥"
    },
    {
      id: 10,
      sect: "interview",
      title: "Harvard Business School",
      description: "Now a Chief Strategy Officer at a Tech Company.",
      imageUrl: "/blog_photos/Blog Post - Interviews.jpg",
      link: "postten",
      type: "meet the geniuses 👋",
      type: "harvard 🔥"
    },
    {
      id: 11,
      sect: "post",
      title: "Cracking the code: What Harvard Business School really wants",
      description: "What exactly is HBS looking for in an MBA applicant?... ",
      imageUrl: "/blog_photos/Blog Post - Cracking the code.jpg",
      link: "postone",
      type: "harvard 🔥",
      type: "cracking the code 🔓"
    },
    {
      id: 12,
      sect: "post",
      title: "Harvard Business School",
      description: "Now a Chief Strategy Officer at a Tech Company.",
      imageUrl: "/blog_photos/Blog Post - Cracking the code.jpg",
      link: "posttwo",
      type: "meet the geniuses 👋",
      type: "harvard 🔥"
    },
  ];
  
  export default blogPosts;
  