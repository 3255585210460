const ImageGallery = () => {
    return (
        <div className="flex flex-col justify-center items-center ">
            <div>
                <h1 className="text-3xl font-bold mb-3 max-sm:mx-[6vw] max-sm:text-center max-sm:max-w-screen-sm">Trusted by non-traditional applicants and employees from leading pre-MBA firms</h1>
            </div>
            <div className="flex flex-row justify-center items-center p-4 w-4/5">
                <img src="i.png" alt="Image 1" style={{ width: '8vw', height: '90%' }} className="mr-1 max-sm:invisible max-sm:absolute" />
                <img src="j1.png" alt="Image 2" style={{ width: '8vw', height: '90%' }} className="mr-4 max-sm:invisible max-sm:absolute" />
                <img src="k.png" alt="Image 3" style={{ width: '8vw', height: '90%' }} className="mr-4 max-sm:invisible max-sm:absolute" />
                <img src="l.png" alt="Image 4" style={{ width: '8vw', height: '90%' }} className="mr-6 max-sm:invisible max-sm:absolute" />
                <img src="m1.png" alt="Image 5" style={{ width: '7vw', height: '90%' }} className="mr-6 max-sm:invisible max-sm:absolute" />
                <img src="n.png" alt="Image 6" style={{ width: '7vw', height: '90%' }} className="mr-6 max-sm:invisible max-sm:absolute" />
                <img src="o.png" alt="Image 7" style={{ width: '8vw', height: '90%' }} className="mb-4 max-sm:invisible max-sm:absolute"/>
                <img src="l.png" alt="Image 7" style={{ width: '30vw', height: '90%' }} className="mr-4 mt-2 md:invisible md:absolute"/>
                <img src="n.png" alt="Image 6" style={{ width: '20vw', height: '90%' }} className="mr-4 mt-4 md:invisible md:absolute" />
                <img src="i.png" alt="Image 2" style={{ width: '25vw', height: '90%' }} className="mt-2 md:invisible md:absolute" />
            </div>
            <h1 className="md:invisible md:absolute font-semibold -mt-2">and more...</h1>
        </div>
    );
};


export default ImageGallery;