import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { MailOutline } from "react-ionicons";
import StripeLogo from "./stripe-logo.png"; // Import your Stripe logo image
import { loadStripe } from '@stripe/stripe-js';
import { Oval } from 'react-loader-spinner';
const CheckoutForm = (targetSchool, preMbaIndustry, Nationality, education, PMG, diversity) => {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const stripePromise = loadStripe('pk_live_51P0D0200VzqWk9s7dsHnt701lslmgX6LutJDM1eYSD6DdQgqST2ovfBcRlFTvmdPkHZYl2vNbbP0FPfp7qDVR0dc00ipgN74Ky');
  const [loading, setLoading] = useState(false);
  const [incorrect, setIncorrect] = useState(true);

  let PromotionCode = "TEST"

  const handleNameChange = (event) => {
    if (name !== "") {
      setLoading(false)
      setIncorrect(true);
    } else {
      setLoading(false);
      setIncorrect(false);
    }
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    if (email !== "") {
      setLoading(false);
      setIncorrect(true);
    } else {
      setLoading(false);
      setIncorrect(false);
    }
    setEmail(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value, 10));
  };

  const handlePromoChange = (event) => {
    setPromoCode(event.target.value);
  }

  const stripeIntegration = async () => {
    const stripe = await stripePromise;
    setLoading(true);
    const response = await fetch('/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quantity,
        email,
        name,
        targetSchool,
        preMbaIndustry,
        Nationality,
        education,
        PMG,
        diversity,
        promoCode,
      }),
    });

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    setLoading(false)

    if (result.error) {
      setLoading(false)
      alert("There was an error. Please contact us and we will help you out personally!")
      console.error(result.error.message);
    }
  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setPaymentError(error.message);
        setPaymentSuccess(null);
      } else {
        setPaymentError(null);
        setPaymentSuccess(paymentMethod);
        // You can handle the successful payment here, such as sending the paymentMethod.id to your backend for processing
      }
    } catch (error) {
      setPaymentError(error.message);
      setPaymentSuccess(null);
    }
  };

  const getPrice = () => {
    // Calculate the price based on quantity (e.g., $10 per unit)
    if (promoCode === PromotionCode) {
       // setShowCoupon(true)
      return 0;
    }
    return quantity * 29;
  };

  return (
    <div className="overflow-y-hidden max-h-[75vh]">
      {loading && !incorrect && (
              <div className="absolute z-30 left-1/2 top-1/2 -ml-10">
                <Oval
                  visible={true}
                  color="black"
                  secondaryColor="#5987FF"
                  strokeWidth="3"
                />
              </div>
            )}
      <form onSubmit={handleSubmit} className="p-1">
          <div className="text-sm mr-3 float-right text-red-500">*</div>
        <div className="py-3">
          <input
            className=" bg-gray-100 rounded-sm pl-[4px] border-gray-200 w-full border-2 p-1"
            placeholder="Name"
            type="text"
            id="name"
            required="true"
            value={name}
            onChange={handleNameChange}
          />
          
        </div>
        <div className="text-sm mr-3 float-right text-red-500">*</div>
        <div className="py-3">
          <input
            className=" bg-gray-100 rounded-sm pl-[4px] border-gray-200 w-full border-2 p-1"
            placeholder="Email"
            type="email"
            id="email"
            required="true"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
          <div className="w-full flex flex-row">
            <div className="py-3 float-left">
              <label
                className="bg-gray-100 rounded-sm pl-[4px] text-gray-400 w-full p-1"
                htmlFor="quantity"
              >
                Quantity:
              </label>
              <input
                className=" bg-gray-100 text-gray-500 rounded-sm pl-[3px]"
                type="number"
                id="quantity"
                min="1"
                max="4"
                value={quantity}
                onChange={handleQuantityChange}
              />
            </div>
          </div>
                  <div className="py-3">
          <button
            onClick={stripeIntegration}
            className="text-gray-500  text-sm"
          >
            <span className="pl-2">Checkout with</span>
            <img src={StripeLogo} alt="Stripe" style={{ width: "70px" }} />
          </button>
          <span className=" bg-[#635AFF] text-[#F3F4F6] rounded-md px-2 float-right mt-4">
            ${getPrice()}
          </span>
        </div>      
        </form>
        <span className="text-gray-400 ml-3 text-xs">Coupon codes can be applied at checkout.</span>
        
    </div>
  );
};

export default CheckoutForm;
