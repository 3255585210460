import React, { useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
} from "firebase/firestore/lite";
import { ArrowBack } from "react-ionicons";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Link,
} from "react-router-dom";
import blogs from "./components/classicBlogData";

import CheckoutForm from "./checkoutForm.js";

import SmallFooter from "./components/Compound/Footer/SmallFooter";
import Header from "./components/Compound/Header/Header";

const Blog = () => {
  const { postId } = useParams();
  const post = blogs[postId];

  return (
    <div>
      <Header></Header>

      {blogs ? (
        <>
          <div className="mt-24">
            <Link to={`/blog`}>
              <div className="ml-40 flex flex-row hover:text-blue-400">
                <ArrowBack height="1rem" color="gray"></ArrowBack>
                <p4 className="text-sm -mt-0.5 ml-0.5
                ">Back</p4>
              </div>
            </Link>
            <h2 className="text-4xl mt-10 mb-5 pb-3 text-center">
              {post.title}
            </h2>
            <h2 className="text-base mx-60 text-center mt-4" >
              🌎 {post.description}
            </h2>
            <div className="mx-40 mt-10 text-lg">
              <p className="font-bold mb-5 mt-10">1. {post.titleOne}</p>
              <p className="text-base">
                {post.sectOne.map((paragraph, index) => (
                  <>
                    <p key={index}>{paragraph}</p>
                    <br></br>
                  </>
                ))}
              </p>
              <p className="font-bold mb-5 mt-10">2. {post.titleTwo}</p>
              <p className="text-base">
                {post.sectTwo.map((paragraph, index) => (
                  <>
                  <p key={index}>{paragraph}</p>
                  <br></br>
                </>
                ))}
              </p>
              <p className="font-bold mb-5 mt-10">3. {post.titleThree}</p>
              <p className="text-base">
                {post.sectThree.map((paragraph, index) => (
                  <>
                  <p key={index}>{paragraph}</p>
                  <br></br>
                </>
                ))}
              </p>
              <p className="font-bold mb-5 mt-10">4. {post.titleFour}</p>
              <p className="text-base">
                {post.sectFour.map((paragraph, index) => (
                  <>
                  <p key={index}>{paragraph}</p>
                  <br></br>
                </>
                ))}
              </p>
              <p className="font-bold mb-5 mt-10">5. {post.titleFive}</p>
              <p className="text-base">
                {post.sectFive.map((paragraph, index) => (
                  <>
                  <p key={index}>{paragraph}</p>
                  <br></br>
                </>
                ))}
              </p>
              <p className="font-bold mb-5 mt-10">6. {post.titleSix}</p>
              <p className="text-base">
                {post.sectSix.map((paragraph, index) => (
                  <>
                  <p key={index}>{paragraph}</p>
                  <br></br>
                </>
                ))}
              </p>
              <p className="mt-7">
                <span className="font-bold text-xl">{post.titleClose}</span>
                <br />
                <br />
                {post.sectClose.map((paragraph, index) => (
                  <>
                  <p key={index}>{paragraph}</p>
                  <br></br>
                </>
                ))}
              </p>
              <div className="bg-black h-[.1rem] mt-7 mb-7"></div>
              <div className="mb-20">
                <div className="mb-7">
                  Ready to ace your MBA application? Click here to unlock
                  exclusive, winning essays and start your journey to business
                  school success today! 🚀{" "}
                  <a
                    className="text-blue-500 underline hover:text-blue-300"
                    href="/essayBank"
                  >
                    here
                  </a>{" "}
                  👀
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h2>Post not found</h2>
      )}
    </div>
  );
};

export default Blog;
