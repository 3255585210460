import { Element, scroller } from "react-scroll";
import Navbar from './Navbar'

const MainHeader = () => {

    const handleAbout = () => {
        setTimeout(() => {
          scroller.scrollTo("about", {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: 190, // Scrolls to element + 440 pixels down the page
            // ... other options
            ignoreCancelEvents: true,
          });
        }, 0);
      }

      const handleServices = () => {
        setTimeout(() => {
          scroller.scrollTo("services", {
            duration: 2000,
            delay: 100,
            smooth: true,
            offset: -10, // Scrolls to element + 440 pixels down the page
            // ... other options
            ignoreCancelEvents: true,
          });
        }, 0);
      }

      const handleFaq = () => {
        setTimeout(() => {
          scroller.scrollTo("faq", {
            duration: 2000,
            delay: 100,
            smooth: true,
            offset: -10, // Scrolls to element + 440 pixels down the page
            // ... other options
            ignoreCancelEvents: true,
          });
        }, 0);
      }

    return (
        <nav className="bg-[#5683f6] top-0 z-50 fixed w-screen drop-shadow-md">
            <div className="w-11/12 mx-auto px-4">
                <div className="flex justify-between">
                    <div className="flex space-x-7">
                        <div>
                            <a className="flex items-center py-4 px-2" href="/">
                                <span className="font-semibold text-white text-3xl">
                                    MBA Essay Genius
                                </span>
                            </a>
                        </div>
                    </div>
                    < Navbar />
                    <div className="hidden md:flex items-center space-x-1">
                        {/* <a
                            className="py-4 px-3  text-white hover:text-blue-200 font-semibold cursor-pointer"
                            href="/blog"
                        >
                            Read our Blog
                        </a> */}
                        <a
                            className="py-4 px-3  text-white hover:text-blue-200 font-semibold cursor-pointer"
                            onClick={handleAbout}
                        >
                            About Us
                        </a>
                        <a
                            className="cursor-pointer py-4 px-3  text-white font-semibold hover:text-blue-200 onClick:border-b-4 border-green-500 transition duration-300"
                            onClick={handleServices}
                        >
                            Services
                        </a>
                        <a
                            className="py-4 px-3  text-white font-semibold hover:text-blue-200 transition duration-300 cursor-pointer"
                            onClick={handleFaq}
                        >
                            FAQs
                        </a>
                        <div className="pl-9">
                            <a
                                className="px-2 py-1 rounded-md font-semibold text-gray-800 hover:text-gray-600 transition duration-300 bg-gradient-to-tr from-[#ff930f] via-[#fffb91] to-[#ffd95b]"
                                href="/essayBank"
                            >
                                Essay Bank
                            </a>
                        </div>
                        <div className="py-4 px-2 pl-9">
                            <a
                                className=" font-semibold hover:text-blue-400 transition duration-300 px-2 py-1 bg-white text-[#5683f6] rounded-md"
                                href="https://docs.google.com/forms/d/e/1FAIpQLSflsVj36xRcAfx_ex6hFL5YcXmhW0tzZ_KTSG2yHtLGK-0ocw/viewform?usp=sf_link"
                            >
                                MBA Grad?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default MainHeader;