import { Element } from "react-scroll";
//import folder from 'public/folder.gif'
import { LockOpenOutline,  } from "react-ionicons";
const AboutUsSection = () => {
  return (
    <section
      className="md:section-2 bg-white flex w-screen justify-center items-center mx-auto md:p-16 mt-[5vh] max-w-[100vw]"
      id="about"
    >
      <Element name="matrix"></Element>

      <div className="md:w-4/5 max-sm:flex max-sm:flex-col max-sm:items-center">
        <img src="notebook.svg" className="relative max-w-[15vw] float-right -mt-[8vh] max-sm:invisible max-sm:absolute"></img>
        <p className="text-p text-gray-700 md:text-left md:float-left md:w-[50vw] max-sm:w-[90vw] mt-[2vh] mb-[8vh] max-sm:mb-[2vh] border-b-4 pl-2 border-[#5987FF] bg-[#CDDBFF] p-5 rounded-lg">
          <b className="text-xl mb-10">
            Unlock the mysterious world of MBA applications without breaking the
            bank.{" "}
          </b>{" "}
          <br></br>
          <span className="max-sm:invisible max-sm:absolute">
          Forget shelling out thousands on an MBA coach – we've got your back
          with a budget-friendly alternative (or complement) to those sky-high
          consulting fees.
          </span>
        </p>
        <img src="wall.svg" className=" max-w-[15vw] float-left -mt-[4vh] invisible absolute"></img>
        <p className="text-p text-gray-700 max-sm:mb-[2vh] md:text-right md:w-[50vw] mb-[8vh] max-sm:w-[90vw] md:float-right border-b-4 max-sm:pl-2 md:pr-4 border-[#5987FF] bg-[#CDDBFF] p-5 rounded-lg">
          <b className="text-xl mb-10">
            Dive into successful applicants’ essays to see the real
            storytelling skills you need!
          </b>{" "}
          <br></br>
          <span className="max-sm:invisible max-sm:absolute">
          Embark on a journey behind the scenes, where real applicants reveal
          the ins and outs of their triumphant MBA narratives.
          </span>
        </p>
               
        <p className="text-p text-gray-700 md:float-left w-[50vw] max-sm:w-[90vw] md:text-left md:mr-[70vw] border-b-4 pl-2 border-[#5987FF] bg-[#CDDBFF] p-5 rounded-lg">
          <b className="text-xl mb-10">
            {" "}
            Discover the secrets of triumph from individuals who walked a
            similar path pre-MBA. <br></br>
          </b>{" "}
          <span className="max-sm:invisible max-sm:absolute">
          Whether you're a number-crunching finance guru or a consultant
          mastermind, we've got essays from people with a similar background.
          </span>
        </p>
        <img src="blogging.svg" className="relative max-w-[15vw] float-right -mt-[18vh] max-sm:invisible max-sm:absolute"></img>   
      </div>
    </section>
  );
};

export default AboutUsSection;
